import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { authHelpers, analyticsHelpers } from '@pluralcom/plural-js-utils';
import queryString from 'query-string';

import {
  ButtonFlat,
  InputText,
  Text,
  InputTextRefHandle,
  SwitchTab,
  InputPhone,
} from '@pluralcom/blueprint';
import { InputPhoneRefHandle } from '@pluralcom/blueprint/dist/react/molecules/InputPhone/InputPhone';

import { useLocation } from 'react-router-dom';
import { withRouter } from '../../../hocs/withRouter/withRouter';

import { errorTexts, inputsMaxLengths } from '../../../assets/data';
import { authMainFormUpdate as authMainFormUpdateAC } from '../../../redux/reducers/authReducer/authReducer';
import { ErrorText, AlertBox } from '../../../components';

import { RequestLoginPinMutation } from '../../../graphql/mutations';
import { logger, cookiesHelpers, mixpanelHelpers } from '../../../utils';

import { Container, NavigationText, SeperatorText } from '../components';
import styles from './Login.module.scss';
import ExternalAuthButtons from '../components/ExternalAuthButtons/ExternalAuthButtons';

interface LoginProps {
  /** Custom class name */
  className: string;
  /** Function to call when login is successful */
  onSuccess?: Function;
  /** Function to call when user wants to sign up */
  onClickSignup?: Function;
  /** identifier to add vertical padding */
  withScreenSpacing?: boolean;
  /** Redux state - authMainForm */
  auth_reqid?: boolean;
  /** Redux state - authMainForm */
  authMainFormUpdate: Function;
  /** Redux state - authMainForm */
  authMainForm: {
    /** Redux state - authMainForm */
    emailOrPhone?: string;
    /** Redux state - authMainForm */
    auth_reqid: boolean;
  };
}

/**
 * 
 * FIGMA v0.0.2 
 * Login component is used to render the login form 
 */
const Login = ({
  className,
  authMainForm,
  onSuccess,
  onClickSignup,
  withScreenSpacing,
  authMainFormUpdate,
}: LoginProps) => {
  const location = useLocation();
  const inputRef = useRef<InputTextRefHandle>(null);
  // seperate ref handler is used for inputPhone to handle focus state
  const inputPhoneRef = useRef<InputPhoneRefHandle>(null);

  const cookiesEnabled = cookiesHelpers.isCookiesEnabled();

  const [emailOrPhoneError, setEmailOrPhoneError] = useState('');
  const [serverError, setServerError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isPhoneAuthModeOpen, setIsPhoneAuthModeOpen] = useState(false);

  const { emailOrPhone, auth_reqid: existing_auth_reqid } = authMainForm;

  /**
   * _blurRef  - call the blur on textfield
   */

  const _blurRef = () => {
    if (isPhoneAuthModeOpen) {
      // @ts-ignore
      inputPhoneRef?.current?.handleOnBlur?.();
      return;
    }
    inputRef?.current?.inputRef?.current?.blur?.();
  };

  const navTextActionProps = onClickSignup
    ? { linkOnClick: onClickSignup }
    : { linkTo: '/signup' };

  const _handleChangeInput = useCallback(
    ({ target: { name, value } }) => {
      authMainFormUpdate({ [name]: value });
      setEmailOrPhoneError('');
    },
    [authMainFormUpdate],
  );

  const _getFieldError = (name: string, value: string) => {
    switch (name) {
      case 'emailOrPhone': {
        const _emailOrPhone = authHelpers.parseMainAuthInputField(value.toString());
        if (!(_emailOrPhone && Object.keys(_emailOrPhone)?.length)) {
          if (!isPhoneAuthModeOpen) {
            return errorTexts.invalidEmail;
          }
          return errorTexts.invalidPhoneNumber;

        }
        return null;
      }
      default:
        return null;
    }
  };

  const _getFormErrors = () => {
    const error = _getFieldError('emailOrPhone', emailOrPhone || '');
    setEmailOrPhoneError(error || '');
    if (error) {
      return true;
    }
    return false;
  };

  const _onSuccess = (profile, data) => {
    if (onSuccess) {
      onSuccess(profile, data);
    }
  };

  const _submit = (event?: Event) => {
    event?.preventDefault();
    // call textField's blur() on submit
    _blurRef();

    const hasError = _getFormErrors();
    if (hasError) {
      return;
    }
    setIsLoading(true);
    RequestLoginPinMutation({
      ...authHelpers.parseMainAuthInputField(emailOrPhone),
      auth_reqid: existing_auth_reqid,
    })
      .then(
        ({
          requestLoginPin: { auth_reqid, profile, error: serverErrorRes },
        }) => {
          const success = !serverErrorRes;
          setServerError(serverErrorRes ? serverErrorRes.message : null);
          setIsLoading(false);
          authMainFormUpdate({ auth_reqid });
          if (success) {
            _onSuccess(profile, {
              emailOrPhone: emailOrPhone?.replaceAll('+', '%2B'),
              auth_reqid,
            });
          }
        },
      )
      .catch((err) => {
        logger.error(err && err.message);
        setServerError(
          `${errorTexts.networkErrorEncountered} ${errorTexts.pleaseTryAgain}`,
        );
        setIsLoading(false);
      });
  };

  /**
   * _focusRef  - sets the focus to the email/phone field
   */
  const _focusRef = () => {
    if (isPhoneAuthModeOpen) {
      // @ts-ignore
      inputPhoneRef?.current?.handleOnFocus?.();
      return;
    }
    inputRef?.current?.inputRef?.current?.focus();
  };

  /** Focus on input field on mount */
  useEffect(() => {
    _focusRef()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPhoneAuthModeOpen]);

  /** Parse route params data and prefill form if needed */
  useEffect(() => {
    // eslint-disable-next-line no-shadow
    const { u: emailOrPhone } = queryString.parse(location?.search) || {};
    const _emailOrPhone = authHelpers.parseMainAuthInputField(emailOrPhone);

    if (
      !authMainForm.emailOrPhone &&
      emailOrPhone &&
      Object.keys(_emailOrPhone)?.length
    ) {
      _handleChangeInput({ target: { name: 'emailOrPhone', value: emailOrPhone } });
    }
  }, [location, authMainForm, _handleChangeInput]);

  return (
    <Container
      className={classNames([styles.container, className])}
      withSpacing={withScreenSpacing}
    >
      <div className={styles['sub-container']}>
        <div className={styles.content}>
          <Text
            fontSizeType="t2"
            stickToBreakpoint="lg"
            className={styles.text}
            element="span"
          >
            Login
          </Text>
          <ExternalAuthButtons
            onSuccess={_onSuccess}
            setServerError={setServerError}
          />
        </div>
        <SeperatorText />

        {!cookiesEnabled && (
          <AlertBox
            title="Cookies Required"
            body="Cookies are not enabled on your browser. Please enable cookies in your browser preferences to continue."
          />
        )}
        <div className={styles.form}>
          <div className={styles.formcontent}>
            <SwitchTab
              tabs={['Email', 'Phone']}
              activeTab={isPhoneAuthModeOpen ? 'Phone' : 'Email'}
              className={styles['switch-tab']}
              onClick={(tab) => {
                const _tab = String(tab);
                setIsPhoneAuthModeOpen(_tab === 'Phone');
                /** clear email/phone field on tab switch 
                 * clear error message as well
                 */
                setEmailOrPhoneError('');
                authMainFormUpdate({ 'emailOrPhone': '' });
              }}
            />
            {isPhoneAuthModeOpen ? <InputPhone
              ref={inputPhoneRef}
              className={classNames([styles['input-phone-container'],
              styles['input-text-container']])}
              // @ts-ignore-next-line
              name="emailOrPhone"
              helperText={emailOrPhoneError || ''}
              placeholder="Phone number"
              value={emailOrPhone}
              onFocus={() => {
                if (emailOrPhoneError) {
                  setEmailOrPhoneError('');
                }
              }}
              onPhoneNumberChange={({ number }) => {
                if (emailOrPhone && emailOrPhone.length === 0) {
                  mixpanelHelpers.trackEvent(
                    analyticsHelpers.events.LOGIN_INPUT_CHANGE.name,
                  );
                }
                _handleChangeInput({ target: { 'name': 'emailOrPhone', 'value': number } });
              }}
              hasError={!!emailOrPhoneError}
              maxLength={inputsMaxLengths.email}
              onMouseDown={() => {
                mixpanelHelpers.trackEvent(
                  analyticsHelpers.events.LOGIN_INPUT_FOCUS.name,
                );
              }}
              onKeyUp={(event: any) => {
                const { key } = event;
                if (key === 'Enter') {
                  _submit();
                }
              }}
              formatOnInit={false}
              {...{
                'data-testid': 'sign_in__inputs__email',
              }}
            /> :

              <InputText
                ref={inputRef}
                name="emailOrPhone"
                className={styles['input-text-container']}
                helperText={emailOrPhoneError || ''}
                placeholder="Email"
                value={emailOrPhone}
                onFocus={() => {
                  if (emailOrPhoneError) {
                    setEmailOrPhoneError('');
                  }
                }}
                onChange={(e: any) => {
                  if (emailOrPhone && emailOrPhone.length === 0) {
                    mixpanelHelpers.trackEvent(
                      analyticsHelpers.events.LOGIN_INPUT_CHANGE.name,
                    );
                  }
                  _handleChangeInput(e);
                }}
                hasError={!!emailOrPhoneError}
                maxLength={inputsMaxLengths.email}
                onMouseDown={() => {
                  mixpanelHelpers.trackEvent(
                    analyticsHelpers.events.LOGIN_INPUT_FOCUS.name,
                  );
                }}
                onKeyUp={(event: any) => {
                  const { key } = event;
                  if (key === 'Enter') {
                    _submit();
                  }
                }}
                helperTextProps={{
                  'data-testid': 'sign_in__errors__email',
                }}
                {...{
                  'data-testid': 'sign_in__inputs__email',
                }}
              />}
            <ErrorText error={serverError} />
            <ButtonFlat
              className={styles['submit-button']}
              uiType="primary"
              size="lg"
              text="Continue"
              data-testid="sign_in__buttons__continue"
              disabled={!cookiesEnabled}
              isLoading={isLoading}
              // @ts-ignore-next-line
              onClick={_submit}
              onMouseDown={() =>
                mixpanelHelpers.trackEvent(
                  analyticsHelpers.events.LOGIN_BUTTON_CONTINUE.name,
                )
              }
            />
          </div>
          <div
            role="presentation"
            onMouseDown={() =>
              mixpanelHelpers.trackEvent(
                analyticsHelpers.events.LOGIN_LINK_CREATEACCOUNT.name,
              )
            }
          >
            <NavigationText
              data-testid="sign_in__links__sign_up"
              state="signup"
              {...navTextActionProps}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

const mapStateToProps = ({ auth: { authMainForm } }) => ({
  authMainForm,
});

const mapDispatchToProps = (dispatch) => ({
  authMainFormUpdate: (payload) => dispatch(authMainFormUpdateAC(payload)),
});

export { Login as PureLogin };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
