import React from 'react';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Avatar } from '@pluralcom/blueprint';

import ListItemComplex from '../../../../../ListItemComplex/ListItemComplex';

import styles from './InputUsersListItem.module.scss';

import { InputUsersListItem_user$data } from './__generated__/InputUsersListItem_user.graphql';

interface InputUsersListItemProps {
  /** User */
  user: InputUsersListItem_user$data;
  /** Callback for click event */
  onClick?: Function;
}

/**
 *
 * FIGMA v0.0.1
 *  InputUsersListItem to render a list item of users
 */
const InputUsersListItem = ({
  user,
  onClick,
  ...rest
}: InputUsersListItemProps) => (
  <ListItemComplex
    // @ts-ignore
    buttonLinkProps={{
      onClick: (e) => {
        e?.preventDefault();
        e?.stopPropagation();

        onClick?.(
          /** if the user is a published user -> pass the entity as is */
          user?.published_at
            ? user
            : /** if the user is not published -> pass the first value in known_emails_phones to correct the phone in case it needs correction. No extra user will be created if no need, since the backend maps the email/phone to the ghost user */
              {
                searchTerm:
                  user?.network_info?.known_emails_phones?.[0] || '',
              },
        );
      },
      className: styles['button-link-container'],
    }}
    avatarProps={{
      user: user as React.ComponentProps<typeof Avatar>['user'],
    }}
    title={user?.name || `${user?.first_name} ${user?.last_name}`.trim()}
    subtitle={user?.network_info?.subtitle || ''}
    text={
      user?.network_info?.known_emails_phones?.join?.(', ') ||
      user?.location?.name ||
      user.network_info?.known_emails_phones && user.network_info?.known_emails_phones?.join(', ') ||

      ''
    }
    className={styles.item}
    {...rest}
  />
);

export default createFragmentContainer(InputUsersListItem, {
  user: graphql`
    fragment InputUsersListItem_user on User {
      id
      username
      first_name
      last_name
      name
      profile_color
      avatar {
        id
        smallThumbnail
      }
      location {
        id
        name
      }
      network_info {
        id
        subtitle
        known_emails_phones
      }
      published_at
    }
  `,
});
